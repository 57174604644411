<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 8.5H17M7 12.5H11M12 20.5L8 16.5H5C4.46957 16.5 3.96086 16.2893 3.58579 15.9142C3.21071 15.5391 3 15.0304 3 14.5V6.5C3 5.96957 3.21071 5.46086 3.58579 5.08579C3.96086 4.71071 4.46957 4.5 5 4.5H19C19.5304 4.5 20.0391 4.71071 20.4142 5.08579C20.7893 5.46086 21 5.96957 21 6.5V14.5C21 15.0304 20.7893 15.5391 20.4142 15.9142C20.0391 16.2893 19.5304 16.5 19 16.5H16L12 20.5Z"
      stroke="#A1A1AA"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
