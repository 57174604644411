<script setup lang="ts">
  import { ChevronUpIcon } from '@heroicons/vue/solid';
  import AnnotationIcon from '~/assets/Icons/AnnotationIcon.vue';
  import ComponentSection from '~/components/UI/Drawers/ComponentSection.vue';
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import type { GrantsDrawerType, LineItem } from '~/types';
  import ExpensesStatus from '../../Others/ExpensesStatus.vue';
  import EyeIconOutlined from '~/assets/Icons/EyeIconOutlined.vue';
  import TopToRightAngleIcon from '~/assets/Icons/TopToRightAngleIcon.vue';

  const props = defineProps<{
    items: GrantsDrawerType['selected_grant']['line_items'];
  }>();

  const activeKey: Ref<number[]> = ref([]);

  type StructuredItems = {
    main_line_item: LineItem;
    sub_line_items: LineItem[];
  };

  const structuredItems = computed(() => {
    return props.items.reduce(
      (acc, item) => {
        if (!acc[item.li_text]) {
          acc[item.li_text] = {
            main_line_item: {
              li_text: item.li_text,
              li_amount: 0,
              approved_amount: 0,
              unapproved_amount: 0,
              stats: {
                approved_expenses: {
                  exceeding_grant: 0,
                  within_grant: 0
                },
                unapproved_expenses: {
                  exceeding_grant: 0,
                  within_grant: 0
                },
                total_grant_amount: 0
              }
            },
            sub_line_items: []
          };
        }

        // Only add to sub_line_items if it's not a single item with empty li_subtext
        if (!(acc[item.li_text].sub_line_items.length === 0 && item.li_subtext === '')) {
          acc[item.li_text].sub_line_items.push(item);
        }

        const main = acc[item.li_text].main_line_item;
        main.li_amount += Number(item.li_amount);
        main.approved_amount += item.approved_amount;
        main.unapproved_amount += item.unapproved_amount;
        main.stats.approved_expenses.exceeding_grant += item.stats.approved_expenses.exceeding_grant;
        main.stats.approved_expenses.within_grant += item.stats.approved_expenses.within_grant;
        main.stats.unapproved_expenses.exceeding_grant += item.stats.unapproved_expenses.exceeding_grant;
        main.stats.unapproved_expenses.within_grant += item.stats.unapproved_expenses.within_grant;
        main.stats.total_grant_amount = String(
          Number(main.stats.total_grant_amount) + Number(item.stats.total_grant_amount)
        );

        return acc;
      },
      {} as Record<string, any>
    );
  });

  const groupedArray: Ref<StructuredItems[]> = computed(() => Object.values(structuredItems.value));

  const handleCollapseAll = () => {
    activeKey.value = [];
  };
</script>

<template>
  <ComponentSection title="Grant Line Item">
    <template #callToAction>
      <a-button
        class="bg-transparent border-none shadow-none sm-medium text-zinc-600 p-0 h-fit w-fit"
        @click="handleCollapseAll"
      >
        Collapse All
      </a-button>
    </template>
    <a-collapse
      v-for="(group, index) in groupedArray"
      :key="index"
      v-model:active-key="activeKey"
      collapsible="header"
      class="grant-line-item"
    >
      <template #expandIcon>
        <ChevronUpIcon class="text-black w-4 h-4" />
      </template>
      <a-collapse-panel
        :key="index"
        :show-arrow="activeKey.includes(index) && group.sub_line_items.length > 0"
        :collapsible="group.sub_line_items.length <= 0 ? 'disabled' : 'header'"
      >
        <template #header>
          <div class="w-full h-full flex items-center justify-between px-3 py-2">
            <div class="flex items-center min-w-[150px]">
              <p class="sm-medium text-zinc-800">{{ group?.main_line_item?.li_text }}</p>
            </div>
            <div class="flex items-center gap-8">
              <div class="flex items-center gap-6">
                <p class="sm-medium text-zinc-800 whitespace-nowrap">
                  {{ formatToIndianCurrency(+group?.main_line_item?.li_amount) }}
                </p>
                <AnnotationIcon />
                <ExpensesStatus class="w-[270px]" :stats="group?.main_line_item?.stats" />
              </div>
              <div class="flex">
                <EyeIconOutlined />
              </div>
            </div>
          </div>
        </template>
        <div
          v-for="item in group.sub_line_items"
          :key="item.li_text"
          class="flex flex-col overflow-hidden rounded-b-lg"
        >
          <div class="w-full h-full flex items-center justify-between px-3 py-2 bg-zinc-50 border-t border-zinc-200">
            <div class="flex items-center gap-2 min-w-[150px]">
              <TopToRightAngleIcon class="mb-2" />
              <p class="sm-medium text-zinc-800">{{ item?.li_subtext }}</p>
            </div>
            <div class="flex items-center gap-8">
              <div class="flex items-center gap-6">
                <p class="sm-medium text-zinc-800 whitespace-nowrap">{{ formatToIndianCurrency(+item?.li_amount) }}</p>
                <AnnotationIcon />
                <ExpensesStatus class="w-[270px]" :stats="item?.stats" />
              </div>
              <div class="flex">
                <EyeIconOutlined />
              </div>
            </div>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </ComponentSection>
</template>

<style>
  .grant-line-item .ant-collapse-header {
    padding: 0px !important;
    display: flex;
    position: relative;
  }

  .grant-line-item .ant-collapse-header-text {
    width: 100%;
  }

  .grant-line-item .ant-collapse-expand-icon {
    position: absolute;
    left: 10px;
    border: 1px solid #d4d4d8;
    padding: 0px !important;
    top: 65px;
    background: #fff;
    height: 20px !important;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
  }

  .grant-line-item .ant-collapse-content-box {
    padding: 0px !important;
  }
</style>
