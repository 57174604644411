import { useAuth } from '~/composables/useAuth';
import { Role } from '~/types/auth';

const permissionConfig = new Map([
  [
    '/donations',
    {
      editActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager],
      mailActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager],
      whatsappActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager],
      receiptActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager, Role.ReadOnly],
      viewActionButton: [
        Role.Admin,
        Role.DonationManager,
        Role.DonorManager,
        Role.ReadOnly,
        Role.Auditor,
        Role.DonorAuditor
      ],
      duplicateActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager],
      headerPrimaryButton: [Role.Admin, Role.DonationManager, Role.DonorManager],
      editLastSentButton: [Role.Admin, Role.DonationManager, Role.DonorManager]
    }
  ],
  [
    '/donations/add-new-donation',
    {
      headerPrimaryButton: [Role.Admin, Role.DonationManager, Role.DonorManager]
    }
  ],
  [
    '/donations/edit-donation',
    {
      headerPrimaryButton: [Role.Admin, Role.DonationManager, Role.DonorManager]
    }
  ],
  [
    '/donors',
    {
      editActionButton: [Role.Admin, Role.DonorManager],
      viewActionButton: [Role.Admin, Role.DonorManager, Role.ReadOnly, Role.DonationManager],
      headerPrimaryButton: [Role.Admin, Role.DonorManager, Role.ReadOnly]
    }
  ],
  [
    '/donors/add-new-donor',
    {
      headerPrimaryButton: [Role.Admin, Role.DonorManager, Role.ReadOnly]
    }
  ],
  [
    '/donors/edit-donor',
    {
      headerPrimaryButton: [Role.Admin, Role.DonorManager, Role.ReadOnly]
    }
  ],
  [
    '/grants',
    {
      viewActionButton: [Role.Admin, Role.GrantReportManager, Role.ReadOnly, Role.Auditor, Role.DonorAuditor],
      editActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager],
      duplicateActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager],
      headerPrimaryButton: [Role.Admin, Role.DonorManager, Role.ReadOnly]
    }
  ],
  [
    '/grants/add-new-grant',
    {
      headerPrimaryButton: [Role.Admin, Role.DonorManager, Role.ReadOnly]
    }
  ],
  [
    '/grants/edit-grant',
    {
      headerPrimaryButton: [Role.Admin, Role.DonorManager, Role.ReadOnly],
      viewActionButton: [Role.Admin, Role.GrantReportManager, Role.ReadOnly, Role.Auditor, Role.DonorAuditor],
      closeActionButton: [Role.Admin, Role.GrantReportManager, Role.ReadOnly, Role.Auditor, Role.DonorAuditor]
    }
  ],
  [
    '/pledges',
    {
      editActionButton: [Role.Admin, Role.DonorManager],
      viewActionButton: [Role.Admin, Role.DonorManager, Role.ReadOnly, Role.DonationManager],
      duplicateActionButton: [Role.Admin, Role.DonorManager, Role.ReadOnly],
      headerPrimaryButton: [Role.Admin, Role.DonorManager, Role.ReadOnly]
    }
  ],
  [
    '/pledges/add-new-pledge',
    {
      headerPrimaryButton: [Role.Admin, Role.DonorManager, Role.ReadOnly]
    }
  ],
  [
    '/pledges/edit-pledge',
    {
      headerPrimaryButton: [Role.Admin, Role.DonorManager, Role.ReadOnly]
    }
  ],
  [
    '/pledges',
    {
      viewActionButton: [Role.Admin, Role.GrantReportManager, Role.ReadOnly, Role.Auditor, Role.DonorAuditor],
      mailActionButton: [Role.Admin, Role.GrantReportManager, Role.ReadOnly, Role.Auditor, Role.DonorAuditor]
    }
  ],
  [
    '/pledges/add-new-pledge',
    {
      headerPrimaryButton: [Role.Admin, Role.DonorManager, Role.ReadOnly]
    }
  ]
]);

export function checkPermission(route: string, action: string): boolean {
  const { role } = useAuth();
  const routeConfig = permissionConfig.get(route);

  if (!routeConfig) return false;

  const allowedRoles = routeConfig[action as keyof typeof routeConfig];
  return Array.isArray(allowedRoles) && allowedRoles.some(allowedRole => role.value.includes(allowedRole));
}
