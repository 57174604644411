<script setup lang="ts">
  import { ExclamationCircleIcon } from '@heroicons/vue/solid';
  import TopToRightAngleIcon from '~/assets/Icons/TopToRightAngleIcon.vue';
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import type { Grant } from '~/types';

  const props = defineProps<{
    stats: Grant['stats'];
  }>();

  const totalGrantAmount = computed(() => +props.stats.total_grant_amount);

  const approvedExpenses = computed(
    () => +props.stats.approved_expenses.within_grant + +props.stats.approved_expenses.exceeding_grant
  );
  const approvedWithinGrantsPercentage = computed(() => {
    const percentage = (+props.stats.approved_expenses.within_grant / totalGrantAmount.value) * 100;
    return isNaN(percentage) ? 0 : +percentage.toFixed(1);
  });
  const approvedExceedingGrantPercentage = computed(() => {
    const percentage = (+props.stats.approved_expenses.exceeding_grant / totalGrantAmount.value) * 100;
    return isNaN(percentage) ? 0 : +percentage.toFixed(1);
  });

  const unapprovedExpenses = computed(
    () => +props.stats.unapproved_expenses.exceeding_grant + +props.stats.unapproved_expenses.within_grant
  );
  const unapprovedWithinGrantsPercentage = computed(() => {
    const percentage = (+props.stats.unapproved_expenses.within_grant / totalGrantAmount.value) * 100;
    return isNaN(percentage) ? 0 : +percentage.toFixed(1);
  });
  const unapprovedExceedingGrantPercentage = computed(() => {
    const percentage = (+props.stats.unapproved_expenses.exceeding_grant / totalGrantAmount.value) * 100;
    return isNaN(percentage) ? 0 : +percentage.toFixed(1);
  });

  const currentBalance = computed(() => totalGrantAmount.value - approvedExpenses.value);

  const potentialBalance = computed(() => totalGrantAmount.value - approvedExpenses.value - unapprovedExpenses.value);

  const percentageUtilized = computed(
    () => approvedWithinGrantsPercentage.value + approvedExceedingGrantPercentage.value
  );

  const calculateScaledPercentage = (percentage: number, total: number) => {
    if (total <= 100) return percentage;
    return (percentage / total) * 100;
  };

  const totalPercentage = computed(() => {
    return (
      approvedWithinGrantsPercentage.value +
      approvedExceedingGrantPercentage.value +
      unapprovedWithinGrantsPercentage.value +
      unapprovedExceedingGrantPercentage.value
    );
  });

  const scaledApprovedWithinGrants = computed(() =>
    calculateScaledPercentage(approvedWithinGrantsPercentage.value, totalPercentage.value)
  );

  const scaledApprovedExceedingGrants = computed(() =>
    calculateScaledPercentage(approvedExceedingGrantPercentage.value, totalPercentage.value)
  );

  const scaledUnapprovedWithinGrants = computed(() =>
    calculateScaledPercentage(unapprovedWithinGrantsPercentage.value, totalPercentage.value)
  );

  const scaledUnapprovedExceedingGrants = computed(() =>
    calculateScaledPercentage(unapprovedExceedingGrantPercentage.value, totalPercentage.value)
  );
</script>

<template>
  <a-popover placement="bottom" :arrow="false">
    <template #content>
      <div class="w-[366px] flex flex-col py-2 px-3 gap-2 text-zinc-800">
        <div class="flex justify-between items-center">
          <p class="text-sm font-bold">Total Grant Amount <span class="font-normal">(A)</span></p>
          <p class="text-sm font-bold">{{ formatToIndianCurrency(totalGrantAmount) }}</p>
        </div>
        <div class="flex flex-col border border-[#E4E4E7] bg-[#FAFAFA] rounded">
          <div class="flex justify-between items-center py-1.5 px-1 border-b border-[#E4E4E7] rounded-t">
            <p class="text-sm font-bold">Approved Expenses <span class="font-normal">(B)</span></p>
            <p class="text-sm font-bold">{{ formatToIndianCurrency(approvedExpenses) }}</p>
          </div>
          <div
            class="flex justify-between items-center pl-3 p-1"
            :class="props.stats.approved_expenses.within_grant == 0 ? 'opacity-50' : 'opacity-100'"
          >
            <div class="flex items-center gap-2">
              <div class="flex self-start">
                <TopToRightAngleIcon />
              </div>
              <div class="w-[14px] h-[14px] bg-[#16A34A] rounded-full"></div>
              <p class="sm-medium">Within Grant Amount</p>
            </div>
            <p class="sm-medium">
              {{
                props.stats.approved_expenses.within_grant == 0
                  ? '-'
                  : formatToIndianCurrency(+props.stats.approved_expenses.within_grant)
              }}
            </p>
          </div>
          <div
            class="flex justify-between items-center pl-3 p-1"
            :class="props.stats.approved_expenses.exceeding_grant == 0 ? 'opacity-50' : 'opacity-100'"
          >
            <div class="flex items-center gap-2">
              <div class="flex self-start">
                <TopToRightAngleIcon />
              </div>
              <div class="w-[14px] h-[14px] bg-[#B91C1C] rounded-full"></div>
              <p class="sm-medium">Exceeding Grant Amount</p>
            </div>
            <p class="sm-medium text-[#B91C1C]">
              {{
                props.stats.approved_expenses.exceeding_grant == 0
                  ? '-'
                  : formatToIndianCurrency(+props.stats.approved_expenses.exceeding_grant)
              }}
            </p>
          </div>
        </div>
        <div class="flex flex-col border border-[#E4E4E7] bg-[#FAFAFA] rounded">
          <div class="flex justify-between items-center py-1.5 px-1 border-b border-[#E4E4E7] rounded-t">
            <p class="text-sm font-bold">Expenses On Approval <span class="font-normal">(C)</span></p>
            <p class="text-sm font-bold">{{ formatToIndianCurrency(unapprovedExpenses) }}</p>
          </div>
          <div
            class="flex justify-between items-center pl-3 p-1"
            :class="props.stats.unapproved_expenses.within_grant == 0 ? 'opacity-50' : 'opacity-100'"
          >
            <div class="flex items-center gap-2">
              <div class="flex self-start">
                <TopToRightAngleIcon />
              </div>
              <div class="w-[14px] h-[14px] relative rounded-full overflow-hidden">
                <div class="approval-dot-within-grant"></div>
              </div>
              <p class="sm-medium">Within Grant Amount</p>
            </div>
            <p class="sm-medium">
              {{
                props.stats.unapproved_expenses.within_grant == 0
                  ? '-'
                  : formatToIndianCurrency(+props.stats.unapproved_expenses.within_grant)
              }}
            </p>
          </div>
          <div
            class="flex justify-between items-center pl-3 p-1"
            :class="props.stats.unapproved_expenses.exceeding_grant == 0 ? 'opacity-50' : 'opacity-100'"
          >
            <div class="flex items-center gap-2">
              <div class="flex self-start">
                <TopToRightAngleIcon />
              </div>
              <div class="w-[14px] h-[14px] relative rounded-full overflow-hidden border-black">
                <div class="approval-dot-exceeding-grant"></div>
              </div>
              <p class="sm-medium">Exceeding Grant Amount</p>
            </div>
            <p class="sm-medium text-[#B91C1C]">
              {{
                props.stats.unapproved_expenses.exceeding_grant == 0
                  ? '-'
                  : formatToIndianCurrency(+props.stats.unapproved_expenses.exceeding_grant)
              }}
            </p>
          </div>
        </div>
        <div class="flex justify-between items-center p-1">
          <p class="text-sm font-bold">Current Balance <span class="font-normal">(A - B)</span></p>
          <p class="text-sm" :class="currentBalance < 0 ? 'text-[#B91C1C]' : 'font-bold text-[#27272A]'">
            <span v-if="currentBalance < 0" class="text-[#B91C1C]">(-) </span>
            {{ formatToIndianCurrency(Math.abs(currentBalance)) }}
          </p>
        </div>
        <div class="flex justify-between items-start p-1">
          <div class="flex gap-2">
            <div class="w-[14px] h-[14px] bg-[#D4D4D8] rounded-full mt-[3px]"></div>
            <div class="flex flex-col">
              <p class="text-sm font-bold">Potential Balance <span class="font-normal">(A - B - C)</span></p>
              <p class="text-xs font-normal text-zinc-500">Balance if all expenses are approved</p>
            </div>
          </div>
          <p class="text-sm" :class="potentialBalance < 0 ? 'text-[#B91C1C]' : 'font-bold text-[#27272A]'">
            <span v-if="potentialBalance < 0" class="text-[#B91C1C]">(-) </span>
            {{ formatToIndianCurrency(Math.abs(potentialBalance)) }}
          </p>
        </div>
      </div>
    </template>
    <div class="w-full h-full flex flex-col gap-1 items-center text-xs">
      <div class="flex justify-between w-full">
        <div
          class="text-[#166534] font-medium bg-[#F0FDF4] rounded px-1 py-0.5 flex gap-1"
          :class="percentageUtilized > 100 ? 'border border-[#B91C1C]' : ''"
        >
          <p>
            <span class="font-bold">{{ percentageUtilized }}%</span> Utilised
          </p>
          <ExclamationCircleIcon v-if="percentageUtilized > 100" class="w-4 h-4 text-[#B91C1C]" />
        </div>
        <span class="bg-[#FAFAFA] text-[#18181B] px-1 py-0.5 rounded"
          ><span class="font-bold">{{ formatToIndianCurrency(currentBalance < 0 ? 0 : currentBalance) }}</span> Current
          Bal.</span
        >
      </div>
      <div class="w-full h-2.5 bg-[#F4F4F5] rounded-full relative overflow-hidden flex border border-[#E4E4E7] p-0.5">
        <div class="approved-bar-within-grant" :style="{ width: scaledApprovedWithinGrants + '%', left: 0 }"></div>
        <div
          class="approval-bar-within-grant"
          :style="{
            width: scaledUnapprovedWithinGrants + '%',
            left: scaledApprovedWithinGrants + '%'
          }"
        ></div>
        <div
          class="approved-bar-exceeding-grant"
          :style="{
            width: scaledApprovedExceedingGrants + '%',
            left: scaledApprovedWithinGrants + scaledUnapprovedWithinGrants + '%'
          }"
        ></div>
        <div
          class="approval-bar-exceeding-grant"
          :style="{
            width: scaledUnapprovedExceedingGrants + '%',
            left: scaledApprovedWithinGrants + scaledUnapprovedWithinGrants + scaledApprovedExceedingGrants + '%'
          }"
        ></div>
      </div>
      <div class="flex gap-1 items-center justify-center bg-[#FFF7ED] rounded px-1 py-0.5">
        <p class="text-[#9A3412] font-medium">
          <span class="font-bold">{{ formatToIndianCurrency(unapprovedExpenses) }}</span> On Approval
        </p>
        <ExclamationCircleIcon v-if="unapprovedExceedingGrantPercentage > 0" class="w-4 h-4 text-[#9A3412]" />
      </div>
    </div>
  </a-popover>
</template>

<style>
  .ant-popover .ant-popover-inner {
    padding: 0 !important;
  }
</style>

<style scoped>
  .approved-bar-within-grant {
    height: 100%;
    background-color: #16a34a;
    border-radius: 0px;
    position: absolute;
    top: 0;
    animation: fillWidth 0.5s ease-out forwards;
  }

  .approval-bar-within-grant {
    height: 100%;
    background: repeating-linear-gradient(125deg, #15803d, #15803d 4px, transparent 4px, transparent 8px), #16a34a;
    border-radius: 0x;
    position: absolute;
    top: 0;
    animation: fillWidth 0.5s ease-out forwards;
  }

  .approved-bar-exceeding-grant {
    height: 100%;
    background-color: #b91c1c;
    border-radius: 0px;
    position: absolute;
    top: 0;
    animation: fillWidth 0.5s ease-out forwards;
  }

  .approval-bar-exceeding-grant {
    height: 100%;
    background: repeating-linear-gradient(125deg, #991b1b, #991b1b 4px, transparent 4px, transparent 8px), #b91c1c;
    border-radius: 0px;
    position: absolute;
    top: 0;
    animation: fillWidth 0.5s ease-out forwards;
  }

  .approval-dot-within-grant {
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(125deg, #15803d, #15803d 3px, transparent 3px, transparent 6px), #16a34a;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .approval-dot-exceeding-grant {
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(125deg, #991b1b, #991b1b 3px, transparent 3px, transparent 6px), #b91c1c;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
  }

  @keyframes fillWidth {
    from {
      transform: scaleX(0);
      transform-origin: left;
    }

    to {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
</style>
