import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as indexxE7jqVeAcsMeta } from "/app/pages/advances/index.vue?macro=true";
import { default as indexI08es032vOMeta } from "/app/pages/budgets/index.vue?macro=true";
import { default as add_45new_45donationM7hsgjXqmiMeta } from "/app/pages/donations/add-new-donation.vue?macro=true";
import { default as edit_45donationUBjzYjweO7Meta } from "/app/pages/donations/edit-donation.vue?macro=true";
import { default as indexWUuiwNpZZeMeta } from "/app/pages/donations/index.vue?macro=true";
import { default as indexx5kczy2fnOMeta } from "/app/pages/donors/add-new-donor/index.vue?macro=true";
import { default as indexwytB7jBwBYMeta } from "/app/pages/donors/edit-donor/index.vue?macro=true";
import { default as indextvCs4IDz35Meta } from "/app/pages/donors/index.vue?macro=true";
import { default as indexqQwwGsgaUsMeta } from "/app/pages/expenses/index.vue?macro=true";
import { default as indexbaVB2GpKMcMeta } from "/app/pages/forbidden/index.vue?macro=true";
import { default as add_45new_45grantmzQDIYVJCTMeta } from "/app/pages/grants/add-new-grant.vue?macro=true";
import { default as edit_45grantIggDH7BDuGMeta } from "/app/pages/grants/edit-grant.vue?macro=true";
import { default as indexU496UweDhTMeta } from "/app/pages/grants/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as add_45new_45pledgeSAq8LMFyGlMeta } from "/app/pages/pledges/add-new-pledge.vue?macro=true";
import { default as edit_45pledgeGSWan884g4Meta } from "/app/pages/pledges/edit-pledge.vue?macro=true";
import { default as indexs6x39PndESMeta } from "/app/pages/pledges/index.vue?macro=true";
import { default as index6ox58Fk0byMeta } from "/app/pages/setup/index.vue?macro=true";
import { default as indexrVEAE3qysDMeta } from "/app/pages/support/index.vue?macro=true";
import { default as _10be_emailerqybfLUL6swMeta } from "/app/pages/utilities/10be_emailer.vue?macro=true";
import { default as download_formatssRjEOoLPA8Meta } from "/app/pages/utilities/download_formats.vue?macro=true";
import { default as expense_reconciliation_statusaC4g7nGlv9Meta } from "/app/pages/utilities/expense_reconciliation_status.vue?macro=true";
import { default as expense_reconciliationf311jQQMmwMeta } from "/app/pages/utilities/expense_reconciliation.vue?macro=true";
import { default as export_tally_expense2XdAoHHBdGMeta } from "/app/pages/utilities/export_tally_expense.vue?macro=true";
import { default as export_tally_incomeHFXFbXZSeOMeta } from "/app/pages/utilities/export_tally_income.vue?macro=true";
import { default as import_ledgersaNllj7F08EMeta } from "/app/pages/utilities/import_ledgers.vue?macro=true";
import { default as import_paymentsNlDkt2tvZOMeta } from "/app/pages/utilities/import_payments.vue?macro=true";
import { default as indexEzEw8CXtVOMeta } from "/app/pages/utilities/index.vue?macro=true";
import { default as statutory_reportsmeqeQ8mYQAMeta } from "/app/pages/utilities/statutory_reports.vue?macro=true";
import { default as UtilitiesUtils0dyqGvh7uKMeta } from "/app/pages/utilities/UtilitiesUtils.ts?macro=true";
import { default as indexJEJnh0nyRrMeta } from "/app/pages/vendors/index.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "advances",
    path: "/advances",
    component: () => import("/app/pages/advances/index.vue").then(m => m.default || m)
  },
  {
    name: "budgets",
    path: "/budgets",
    component: () => import("/app/pages/budgets/index.vue").then(m => m.default || m)
  },
  {
    name: "donations-add-new-donation",
    path: "/donations/add-new-donation",
    component: () => import("/app/pages/donations/add-new-donation.vue").then(m => m.default || m)
  },
  {
    name: "donations-edit-donation",
    path: "/donations/edit-donation",
    component: () => import("/app/pages/donations/edit-donation.vue").then(m => m.default || m)
  },
  {
    name: "donations",
    path: "/donations",
    component: () => import("/app/pages/donations/index.vue").then(m => m.default || m)
  },
  {
    name: "donors-add-new-donor",
    path: "/donors/add-new-donor",
    component: () => import("/app/pages/donors/add-new-donor/index.vue").then(m => m.default || m)
  },
  {
    name: "donors-edit-donor",
    path: "/donors/edit-donor",
    component: () => import("/app/pages/donors/edit-donor/index.vue").then(m => m.default || m)
  },
  {
    name: "donors",
    path: "/donors",
    component: () => import("/app/pages/donors/index.vue").then(m => m.default || m)
  },
  {
    name: "expenses",
    path: "/expenses",
    component: () => import("/app/pages/expenses/index.vue").then(m => m.default || m)
  },
  {
    name: "forbidden",
    path: "/forbidden",
    component: () => import("/app/pages/forbidden/index.vue").then(m => m.default || m)
  },
  {
    name: "grants-add-new-grant",
    path: "/grants/add-new-grant",
    component: () => import("/app/pages/grants/add-new-grant.vue").then(m => m.default || m)
  },
  {
    name: "grants-edit-grant",
    path: "/grants/edit-grant",
    component: () => import("/app/pages/grants/edit-grant.vue").then(m => m.default || m)
  },
  {
    name: "grants",
    path: "/grants",
    component: () => import("/app/pages/grants/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "pledges-add-new-pledge",
    path: "/pledges/add-new-pledge",
    component: () => import("/app/pages/pledges/add-new-pledge.vue").then(m => m.default || m)
  },
  {
    name: "pledges-edit-pledge",
    path: "/pledges/edit-pledge",
    component: () => import("/app/pages/pledges/edit-pledge.vue").then(m => m.default || m)
  },
  {
    name: "pledges",
    path: "/pledges",
    component: () => import("/app/pages/pledges/index.vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    component: () => import("/app/pages/setup/index.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/app/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "utilities-10be_emailer",
    path: "/utilities/10be_emailer",
    meta: _10be_emailerqybfLUL6swMeta || {},
    component: () => import("/app/pages/utilities/10be_emailer.vue").then(m => m.default || m)
  },
  {
    name: "utilities-download_formats",
    path: "/utilities/download_formats",
    meta: download_formatssRjEOoLPA8Meta || {},
    component: () => import("/app/pages/utilities/download_formats.vue").then(m => m.default || m)
  },
  {
    name: "utilities-expense_reconciliation_status",
    path: "/utilities/expense_reconciliation_status",
    component: () => import("/app/pages/utilities/expense_reconciliation_status.vue").then(m => m.default || m)
  },
  {
    name: "utilities-expense_reconciliation",
    path: "/utilities/expense_reconciliation",
    component: () => import("/app/pages/utilities/expense_reconciliation.vue").then(m => m.default || m)
  },
  {
    name: "utilities-export_tally_expense",
    path: "/utilities/export_tally_expense",
    meta: export_tally_expense2XdAoHHBdGMeta || {},
    component: () => import("/app/pages/utilities/export_tally_expense.vue").then(m => m.default || m)
  },
  {
    name: "utilities-export_tally_income",
    path: "/utilities/export_tally_income",
    meta: export_tally_incomeHFXFbXZSeOMeta || {},
    component: () => import("/app/pages/utilities/export_tally_income.vue").then(m => m.default || m)
  },
  {
    name: "utilities-import_ledgers",
    path: "/utilities/import_ledgers",
    meta: import_ledgersaNllj7F08EMeta || {},
    component: () => import("/app/pages/utilities/import_ledgers.vue").then(m => m.default || m)
  },
  {
    name: "utilities-import_payments",
    path: "/utilities/import_payments",
    meta: import_paymentsNlDkt2tvZOMeta || {},
    component: () => import("/app/pages/utilities/import_payments.vue").then(m => m.default || m)
  },
  {
    name: "utilities",
    path: "/utilities",
    meta: indexEzEw8CXtVOMeta || {},
    component: () => import("/app/pages/utilities/index.vue").then(m => m.default || m)
  },
  {
    name: "utilities-statutory_reports",
    path: "/utilities/statutory_reports",
    meta: statutory_reportsmeqeQ8mYQAMeta || {},
    component: () => import("/app/pages/utilities/statutory_reports.vue").then(m => m.default || m)
  },
  {
    name: "utilities-UtilitiesUtils",
    path: "/utilities/UtilitiesUtils",
    component: () => import("/app/pages/utilities/UtilitiesUtils.ts").then(m => m.default || m)
  },
  {
    name: "vendors",
    path: "/vendors",
    component: () => import("/app/pages/vendors/index.vue").then(m => m.default || m)
  }
]