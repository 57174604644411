<script setup lang="ts">
  import ComponentSection from '~/components/UI/Drawers/ComponentSection.vue';
  import ComponentSectionRow from '~/components/UI/Drawers/ComponentSectionRow.vue';
  import { formatDate } from '~/functions/dateTimeUtilities';
  import type { GrantsDrawerType } from '~/types';

  const props = defineProps<{
    reports: GrantsDrawerType['selected_grant']['reports'];
    reportingEmails: GrantsDrawerType['selected_grant']['reporting_emails'];
    reportingDaysBefore: GrantsDrawerType['selected_grant']['reporting_days_before'];
  }>();

  const emails = computed(() => {
    if (!props.reportingEmails) return [];
    return props.reportingEmails.split(',').map(email => email.trim());
  });
</script>

<template>
  <ComponentSection title="Report Dates">
    <ComponentSectionRow label="Reminder Email List">
      <div v-if="emails.length > 0" class="flex gap-3 flex-wrap">
        <p v-for="email in emails" :key="email" class="bg-zinc-100 px-1 py-0.5 rounded-md">{{ email }}</p>
      </div>
      <p v-else>-</p>
    </ComponentSectionRow>
    <ComponentSectionRow label="Reminder Before">
      <a-input-number disabled :value="props.reportingDaysBefore">
        <template #addonAfter>
          <p>Days</p>
        </template>
      </a-input-number>
    </ComponentSectionRow>
    <div class="border border-zinc-300 rounded overflow-hidden">
      <table class="w-full sm-medium border-collapse rounded">
        <thead>
          <tr class="border-b bg-zinc-50">
            <th class="text-left py-2 px-3 border-r font-medium">Sr</th>
            <th class="text-left py-2 px-3 border-x font-medium">Date</th>
            <th class="text-left py-2 px-3 border-x font-medium">Report Name</th>
            <th class="text-left py-2 px-3 border-l font-medium">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(report, index) in props.reports" :key="report.rd_date">
            <td class="py-2 px-3 border-t border-r">{{ index + 1 }}</td>
            <td class="py-2 px-3 border-x border-t">{{ formatDate(report.rd_date, 'DD/YY/YYYY') }}</td>
            <td class="py-2 px-3 border-x border-t">{{ report.rd_text }}</td>
            <td class="py-2 px-3 border-t border-l">{{ report.rd_status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ComponentSection>
</template>
