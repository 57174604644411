<script setup lang="tsx">
  import EmailReceiptModal from './RoutedModalCollection/EmailReceiptModal.vue';
  import EditLastSent from './RoutedModalCollection/EditLastSent.vue';
  import EmailPledgeReminder from './RoutedModalCollection/EmailPledgeReminder.vue';

  const route = useRoute();
  const router = useRouter();

  const identifier = ref<string | null>(null);
  const modalFor = ref<string | null>(null);
  const oldDate = ref<string | null>(null);

  const onClose = () => {
    const { modalFor, identifier, oldDate, ...rest } = route.query;
    router.replace({ query: { ...rest } });
  };

  watch(
    () => route.query,
    newQuery => {
      if (newQuery.modalFor && newQuery.identifier) {
        identifier.value = newQuery.identifier as string;
        modalFor.value = newQuery.modalFor as string;
        oldDate.value = newQuery.date as string;
      } else {
        identifier.value = null;
        modalFor.value = null;
        oldDate.value = null;
      }
    },
    { immediate: true, deep: true }
  ); //activate modal when route has modalFor and identifier
</script>

<template>
  <EmailReceiptModal
    v-if="identifier && modalFor === 'emailReceipt'"
    :on-close="onClose"
    :identifier="identifier"
    :modal-for="modalFor"
  />
  <EditLastSent
    v-if="identifier && modalFor === 'editLastSent'"
    :on-close="onClose"
    :identifier="identifier"
    :modal-for="modalFor"
    :old-date="oldDate"
  />
  <EmailPledgeReminder
    v-if="identifier && modalFor === 'emailPledgeReminder'"
    :on-close="onClose"
    :identifier="identifier"
    :modal-for="modalFor"
  />
</template>
