<script setup lang="ts">
  const props = defineProps<{
    title?: string;
  }>();
</script>

<template>
  <div class="flex flex-col border-b border-gray-100">
    <div
      v-if="props.title && props.title?.length > 0"
      class="flex justify-between items-center py-3 px-3 border-y border-gray-200 w-full bg-zinc-100 text-sm font-bold text-zinc-800"
    >
      {{ title }}
      <slot name="callToAction" />
    </div>
    <div class="flex flex-col px-3 py-4 gap-4">
      <slot />
    </div>
  </div>
</template>
