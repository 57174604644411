<script setup lang="ts">
  import { ExclamationCircleIcon } from '@heroicons/vue/solid';
  import ComponentSection from '~/components/UI/Drawers/ComponentSection.vue';
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import { formatDate } from '~/functions/dateTimeUtilities';
  import type { GrantsDrawerType } from '~/types';

  const props = defineProps<{
    donations: GrantsDrawerType['donations_against_grant'];
    pendingAmount: number;
  }>();
</script>

<template>
  <ComponentSection title="Donations Recieved">
    <div v-if="props.donations.length > 0" class="border border-zinc-300 rounded overflow-hidden">
      <table class="w-full border-collapse rounded sm-medium">
        <thead>
          <tr class="border-b bg-zinc-50">
            <th class="text-left py-2 px-3 border-r font-medium">Sr</th>
            <th class="text-left py-2 px-3 border-r font-medium">Date</th>
            <th class="text-left py-2 px-3 border-x font-medium">Receipt No.</th>
            <th class="text-left py-2 px-3 border-l font-medium">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(donation, index) in props.donations" :key="donation.date">
            <td class="py-2 px-3 border-t border-r">{{ index + 1 }}</td>
            <td class="py-2 px-3 border-t border-x">{{ formatDate(donation.date, 'DD/MM/YYYY') }}</td>
            <td class="py-2 px-3 border-x border-t">{{ donation.receipt_no }}</td>
            <td class="py-2 px-3 border-t border-l">{{ formatToIndianCurrency(+donation.amount) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex items-center gap-2 border border-yellow-300 bg-yellow-50 px-3 py-2 rounded-lg">
      <ExclamationCircleIcon v-if="props.pendingAmount > 0" class="w-5 h-5 text-yellow-700" />
      <p class="text-sm font-bold text-yellow-700">
        {{ formatToIndianCurrency(props.pendingAmount) }} pending to receive
      </p>
    </div>
  </ComponentSection>
</template>
